/**
 * Frontend scripts
 */

function waitForDynamicFooter() {
    return new Promise((resolve, reject ) =>{
        const startTime = Date.now();
        const interval = setInterval(() =>{
            if (window.DYNAMIC_FOOTER && Array.isArray(window.initialization)){
                clearInterval(interval);
                resolve(window.DYNAMIC_FOOTER);
            } else if (Date.now() - startTime >= 3000 ) {
                clearInterval(interval);
                reject("Timeout: Dynamic_Footer not available after 3 seconds.", Error);
            }
        }, 100);
    });
};

function showMenu(){
    // Check to see if window.initialization is an array (Footer exists).
    if (Array.isArray(window.initialization)) {
        const elementId = window.initialization[2];
        const target = document.getElementById(elementId);
        target.style.display = 'block';
    } else {
        console.log('No initialization data found');
    }
}

waitForDynamicFooter()
    .then(() =>{

        // Initialize Dynamic Footer as the promise has been fulfilled.
        window.DYNAMIC_FOOTER.init(window.initialization);

        // Select the node that will be observed for mutations
        const elementId = window.initialization[2];
        const targetNode = document.getElementById(elementId);

        // Options for the observer (which mutations to observe)
        const config = { childList: true };

        // Callback function to execute when mutations are observed
        const displayFooter = (mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === "childList") {
                    // console.log("A child node has been added or removed.");
                    showMenu();
                    observer.disconnect();
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(displayFooter);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    })
    .catch(() => {
        showMenu();
    });




